export const scrollFixed = () => {
  const elm = document.querySelector('.p-nav');
  const sentinalEl = document.querySelector('.p-nav__hook');
  const options = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0,
  };

  // IntersectionObserverの作成
  const observer = new IntersectionObserver(entries => {
    for (const e of entries) {
      if (e.isIntersecting) {
        elm.classList.remove('is-change');
      } else {
        elm.classList.add('is-change');
      }
    }
  }, options);

  observer.observe(sentinalEl);
};
