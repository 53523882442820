import { closeDrawerHashlink } from './modules/close-drawer-hashlink'; // モバイルナビゲーション
import { drawer } from './modules/drawer'; // モバイルナビゲーション
import { glide } from './modules/glide'; // スライド
import { leaflet } from './modules/leaflet'; // マップ
import { scrollEvent, anchorPosition } from './modules/scroll-event'; // スクロール後のアニメーション
import { scrollFixed } from './modules/scroll-fixed';
import { scrollReveal } from './modules/scrollreveal'; // ページトップに戻る
import { loader } from './modules/loader'; // ページトップに戻る
import { readmore } from './modules/readmore'; // もっと読む

loader();
anchorPosition();
closeDrawerHashlink();
drawer();
glide();
leaflet();
scrollEvent();
scrollFixed();
scrollReveal();
readmore();