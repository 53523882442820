export const readmore = () => {

  document.querySelectorAll('.c-readmore__button').forEach(function(button) {

    button.addEventListener('click', function(event) {
      //console.log('click');

      // 現在の要素の親要素を取得
      var parentElement = event.target.parentElement;

      var siblingElement = parentElement.querySelector('.c-readmore');

      if (siblingElement) {
          //siblingElement.style.display = 'block';
          //siblingElement.classList.remove('c-readmore');


          siblingElement.style.display = 'block';
          setTimeout(function() {
            siblingElement.classList.add('c-readmore__show');
          }, 10); // 微小な遅延を追加してトランジションを適用
      }

      this.remove();
    });

  });
};
