/**
 * 同一ページ内アンカーリンクをクリックしたときにグローバルナビゲーションメニューを閉じる
 */
export const closeDrawerHashlink = () => {
  const links = document.querySelectorAll('.p-header__nav-item > a');
  const linksArr = Array.prototype.slice.call(links);

  linksArr.forEach(link => {
    link.addEventListener('click', e => {
      const target = link.hash;
      const body = document.body;

      if (!target.length) return;
      body.classList.remove('is-opened');
    });
  });
};
