// https://scrollrevealjs.org/
import ScrollReveal from 'scrollreveal';

export const scrollReveal = () => {
  const option = {
    duration: 800, // アニメーションの完了にかかる時間
    viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
    distance: '10px',
    origin: 'bottom',
  };

  ScrollReveal().reveal('.reveal', option);
};
