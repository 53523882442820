/**
 * スクロールに連動するアニメーション
 */

export const scrollEvent = () => {
  // ボタン
  const button = document.getElementById('scrollTop');

  // クリックイベント
  button.addEventListener('click', function() {
    window.scroll({ top: 0, behavior: 'smooth' });
  });

  // スクロール時のイベント
  const scrollDistance = 300;
  window.addEventListener('scroll', function() {
    if (window.pageYOffset > scrollDistance) {
      button.classList.add('is-show');
    } else if (window.pageYOffset < scrollDistance) {
      button.classList.remove('is-show');
    }
  });
};

/**
 * アンカーリンクの位置を調整
 */
export const anchorPosition = () => {
  window.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.s-home .p-nav');
    if (!header) return;
    const headerHeight = header.offsetHeight;
    const anchorLinks = document.querySelectorAll('a[href^="/#"]');
    const anchorLinksArr = Array.prototype.slice.call(anchorLinks);

    anchorLinksArr.forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();
        const targetId = link.hash;
        const targetElement = document.querySelector(targetId);
        const targetOffsetTop =
          window.pageYOffset +
          targetElement.getBoundingClientRect().top -
          headerHeight -
          15;

        window.scrollTo({
          top: targetOffsetTop,
          behavior: 'smooth',
        });
      });
    });
  });
};
