/**
 * 開閉メニューボタン
 */
export const drawer = () => {
  const button = document.getElementById('js-drawerButton');

  button.addEventListener('click', () => {
    document.body.classList.toggle('is-opened');
  });
};
