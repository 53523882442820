export const loader = () => {
  const loadElm = document.querySelector('.p-loading');
  const windowHeight = window.innerHeight;

  loadElm.style.height = `${windowHeight}px`;

  const stopLoad = () => {
    console.log('loaded');
    document.body.classList.add('is-loaded');
  };

  // 読み込み完了でローディング画面を非表示にする
  window.addEventListener('load', stopLoad);

  // 10秒経過したらローディング画面を強制的に非表示にする
  setTimeout(stopLoad(), 10000);
};
