// Install from npm:
// 'npm i @glidejs/glide'
// https://glidejs.com/
import Glide from '@glidejs/glide';

export const glide = () => {
  const elm = document.querySelector('.glide');

  // 要素がない場合は処理を止める
  if (!elm) return;

  new Glide('.glide', {
    gap: 0,
    autoplay: 3000,
  }).mount();
};
